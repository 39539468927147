/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// Import fonts globally
import "./src/fonts/fonts.css"

// You can delete this file if you're not using it

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    return import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}
